import { Component, OnInit } from '@angular/core';
import { Project } from '../models/project';
import { ProjectService } from '../_services/project.service';
import { AlertifyService } from '../_services/alertify.service';

@Component({
  selector: 'app-list-projects',
  templateUrl: './list-projects.component.html',
  styleUrls: ['./list-projects.component.css']
})
export class ListProjectsComponent implements OnInit {
  projectsList: Array<Project>;

  constructor(private projectService: ProjectService, private alertifyService: AlertifyService) { }

  ngOnInit() {
    this.getProjects();
    if (this.projectService.subGetProject==undefined) {    
      this.projectService.subGetProject = this.projectService.    
      invokeGetProjects.subscribe(() => {    
        this.getProjects();    
      });    
    }  
  }

  
  getProjects() {
    this.projectService.listProjects().subscribe((data: Array<Project>) => {
      this.projectsList = data;
    });
  }

  removeProject(element: Project){
    this.projectService.removeProject(element).subscribe(() => {
      this.alertifyService.success('Project removed successfully')
      this.getProjects();
    },  error => {
      this.alertifyService.error(error);
    });
  }

  editProject(project: Project){
    this.projectService.onProjectEdit(project);
  }
  

}
