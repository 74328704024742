import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { tokenName } from '@angular/compiler';
import { Router } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { ExchangedataService } from '../_services/exchangedata.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  constructor(public authService: AuthService, private router: Router, private alertify:
     AlertifyService, private exchangeDate: ExchangedataService) {  }
  isUserAdmin: boolean;

  ngOnInit() {
  }

  loggedIn(){
    return this.authService.isUserLoggedIn();
  }

  editProfile(){
    this.exchangeDate.changePassword = false;
    this.router.navigate(['/edituser', { id: this.authService.decodedToken.nameid }]);
  }

  logout(){
    localStorage.removeItem('token');
    this.router.navigate(['']);
    this.alertify.message('logged out')
  }

}
