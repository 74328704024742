import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  getAll(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'users/getall');
  }

  getByProjectId(projectId: string): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'users/getbyproject/' + projectId);
  }

  update(user: User) {
    return this.http.put<boolean>(this.baseUrl + 'users/update', user);
  }

  getById(id: string): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'users/getById/' + id);
  }

  updatePassword(user: User){
    return this.http.put<boolean>(this.baseUrl + 'users/updatepassword', user);
  }


}
