import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  baseUrl = environment.baseUrl;
constructor(private http: HttpClient) { }


  getByUsers(modelToSend){
    return this.http.post(this.baseUrl + 'reports/getByUsers', modelToSend);
  }

  getByProjects(modelToSend){
    return this.http.post(this.baseUrl + 'reports/getByProjects', modelToSend)
  }

  getByUserId(modelToSend){
    return this.http.post(this.baseUrl + 'reports/getByUserId', modelToSend)
  }

  getByProjectId(modelToSend){
    return this.http.post(this.baseUrl + 'reports/getByProjectId', modelToSend)
  }

  getGrouppedByDate(modelToSend){
    return this.http.post(this.baseUrl + 'reports/getGrouppedByDate', modelToSend);
  }

  getByTicket(ticket, userId){
    return this.http.get(this.baseUrl + 'workinghours/getByTicket/' + ticket + '/'+ userId)
  }

  getByTicketUsers(ticket){
    return this.http.get(this.baseUrl + 'workinghours/getByTicketForUsers/' + ticket);
  }

  getByTicketReport(modelToSend){
    return this.http.post(this.baseUrl + 'reports/getByTicketReport', modelToSend);
  }

  getByTicketReportAdmin(modelToSend){
    return this.http.post(this.baseUrl + 'reports/getByTicketReportAdmin', modelToSend);
  }
}
