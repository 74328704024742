import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { User } from '../models/user';
import { catchError } from 'rxjs/operators';
import { WorkinghoursService } from '../_services/workinghours.service';
import { Workinghours } from '../models/workinghours';
import { AuthService } from '../_services/auth.service';

@Injectable()
export class WorkingHoursResolver implements Resolve<Workinghours>{
    pageNumber = 1;
    pageSize = 10;
    constructor(private _workingHours: WorkinghoursService, private router: Router, private authService: AuthService){}

    resolve(route: ActivatedRouteSnapshot): Observable<Workinghours> {
        return this._workingHours.getWorkingHours(this.pageNumber, this.pageSize, this.authService.decodedToken.nameid).pipe(
            catchError(error => {
                console.log(error);
                this.router.navigate(['/error']);
                return of(null);
            })
        );
    }
}