import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReportService } from '../_services/report.service'
import { ProjectService } from '../_services/project.service';
import { Project } from '../models/project';
import { UserService } from '../_services/user.service';
import { User } from '../models/user';
import { Observable } from 'rxjs';
import { DoCheck, KeyValueDiffers, KeyValueDiffer } from '@angular/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  reportform: FormGroup;
  pagedListParams: any = {};
  reports: any=[];
  projects: Project[];
  users: User[];
  showNoData: boolean = false;
  functionTriggered: boolean = false;
  disableDate: boolean = false;

  differ: KeyValueDiffer<string, any>;
  constructor(private fb: FormBuilder, private reportService: ReportService,
              private projectService: ProjectService, private userService: UserService, private differs: KeyValueDiffers) {
                this.differ = this.differs.find({}).create();
               }

  ngOnInit() {
    // this.reportform = this.fb.group({
    //   date: ['', Validators.required]
    // });
    this.getUsers();
    this.getProjects();
    const datevm = new Date();
    this.pagedListParams.startDateString = new Date(datevm.getFullYear(), datevm.getMonth(), 1);
    this.pagedListParams.endDateString = new Date();
    this.pagedListParams.ticket = "";
    this.pagedListParams.projectId = "00000000-0000-0000-0000-000000000000";
    this.pagedListParams.userId = "00000000-0000-0000-0000-000000000000";
  }

  ngDoCheck() {
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        if(item.key==="reports" && item.currentValue.length === 0 && this.functionTriggered){
          this.showNoData = true
        }
        else{
          this.showNoData = false;
        }
      });
    }
  }


  selectChange() {
    console.log(this.pagedListParams.selectVal);
    this.reports = [];
  }

  getReport(){
    this.functionTriggered = true;
    if(this.pagedListParams.selectVal === 'rep_by_users'){
      this.reportService.getByUsers(this.pagedListParams).subscribe((data: any) => {
        this.reports = [];
        this.getSumHours(data);
      });
    }
    else if(this.pagedListParams.selectVal === 'rep_by_projects'){
      this.reportService.getByProjects(this.pagedListParams).subscribe((data: any) => {
        this.reports = [];
        this.getSumHours(data);
      });
    }
    else if(this.pagedListParams.selectVal === 'rep_by_user'){
      this.reportService.getByUserId(this.pagedListParams).subscribe((data: any) => {
        this.reports = [];
        this.getSumHours(data);
      });
    }
    else if(this.pagedListParams.selectVal === 'rep_by_project'){
      this.reportService.getByProjectId(this.pagedListParams).subscribe((data: any) => {
        this.reports = [];
        this.getSumHours(data);
      });
    }
    else if(this.pagedListParams.selectVal === 'rep_by_ticket'){
      this.reports = [];
      this.reportService.getByTicketReportAdmin(this.pagedListParams).subscribe((data:any) => {
        this.reports = [];
        // this.reports = data;
        this.getSumHours(data);
      });
    }
  }

  getSumHours(data){
    let sum = 0;
    let arrlength = data.length;
    for(const item of data){
      arrlength--;
      sum += item.hours;
      if(arrlength === 0) {
        this.reports = data;
        this.reports.sum = sum;
      }
    }
  }

  toggleDate(){
    this.disableDate = !this.disableDate;
  }

  getProjects() {
    this.projectService.getAll().subscribe((data: Project[]) => {
      this.projects = data;
    });
  }

  getUsers() {
    this.userService.getAll().subscribe((data: User[]) => {
      this.users = data;
    });
  }

}
