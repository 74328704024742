import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from '../models/user';
import { Userproject } from '../models/userproject';
import { WorkinghoursService } from '../_services/workinghours.service';
import { AlertifyService } from '../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Workinghours } from '../models/workinghours';
import { ProjectService } from '../_services/project.service';
import { AuthService } from '../_services/auth.service';
import { Art } from '../models/art';
import { ifStmt } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-editworkinghours',
  templateUrl: './editworkinghours.component.html',
  styleUrls: ['./editworkinghours.component.css']
})
export class EditworkinghoursComponent implements OnInit {
  editWorkingHoursForm: FormGroup;
  users: User[];
  userProjects: Array<Userproject>;
  userFromDb: string;
  arts: Art[];
  constructor(private workingHoursService: WorkinghoursService, 
    private alertifyService: AlertifyService, private route: ActivatedRoute, private authService: AuthService,
    private projectService: ProjectService, private router: Router, private fb: FormBuilder) { }

  ngOnInit() {
    this.editWorkingHoursForm = this.fb.group({
      id: [''],
      dateString: ['', Validators.required],
      projectId: ['', Validators.required],
      userId: ['', Validators.required],
      description: [''],
      ticketWt: ['', Validators.required],
      ticketSf: [''],
      gesellschaft: [''],
      artId: [null],
      hours: ['', Validators.required]
    });
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      console.log(id);
      this.getArts();
      this.workingHoursService.getWorkingHoursById(id).subscribe((data: Workinghours) => {
        console.log(data);
        this.userFromDb = data.user.firstName + ' ' + data.user.lastName;
        //this.editWorkingHoursForm.setValue(data);
        this.editWorkingHoursForm.patchValue({
          id: data.id,
          dateString: new Date(data.date),
          projectId: data.projectId,
          userId: data.userId,
          description: data.description,
          ticketWt: data.ticketWt,
          ticketSf: data.ticketSf,
          gesellschaft: data.gesellschaft,
          artId: data.artId,
          hours: data.hours
        })
        this.getProjectsByUser(data.user.id);
      });
    });
  }

  saveWorkingHours() {
    if(this.editWorkingHoursForm.invalid){
      this.editWorkingHoursForm.markAllAsTouched();
      return;
    }
    const modelToUpdate = Object.assign({}, this.editWorkingHoursForm.value);
    this.workingHoursService.updateWorkingHours(modelToUpdate).subscribe((data) => {
      this.router.navigate(['/workinghours']);
      this.alertifyService.success("Working hours updated successfully");
      this.workingHoursService.lastWorkingHoursEdited = modelToUpdate.id;
    })
  }

  onUserChange(userValue){
    if (this.authService.decodedToken.role === 'isAdmin') {
      this.getProjectsByUser(userValue);
    }
  }

  getArts() {
    this.workingHoursService.getArts().subscribe((data: Art[]) => {
      this.arts = data;
    });
  }

  getProjectsByUser(userId) {
    this.projectService.getByUserId(userId.toString()).subscribe((data) => {
      this.userProjects = data;
    });
  }

}
