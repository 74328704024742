import { Component, OnInit, Inject, LOCALE_ID, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WorkinghoursService } from '../_services/workinghours.service';
import { Workinghours } from '../models/workinghours';
import { AuthService } from '../_services/auth.service';
import { ProjectService } from '../_services/project.service';
import { Userproject } from '../models/userproject';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../_services/user.service';
import { ReportService } from '../_services/report.service';
import { User } from '../models/user';
import { AlertifyService } from '../_services/alertify.service';
import { PaginatedResult, Pagination } from '../models/pagination';
import { Project } from '../models/project';
import { formatDate } from '@angular/common';
import { PagedListParams } from '../models/pagedListParams';
import * as xlsx from 'xlsx'
import { Art } from '../models/art';

@Component({
  selector: 'app-workinghours',
  templateUrl: './workinghours.component.html',
  styleUrls: ['./workinghours.component.css']
})
export class WorkinghoursComponent implements OnInit {
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  workinghoursform: FormGroup;
  addFormActivated: boolean;
  workingHours: Workinghours[];
  workingHoursToCreate: Workinghours;
  userProjects: Array<Userproject>;
  users: User[];
  pagination: Pagination;
  projects: Project[];
  pagedListParams: any = {};
  bsConfig;
  pageSizeToSend:number = 10;
  arts: Art[];
  objLength: number;
  showModal: boolean = false;
  ticketReport: any = []
  webServicesProjects = ['79623e5d-7ad8-45d4-b0ba-c96d8000f05b',
  '3d73bdbf-aa92-4288-a3a4-9450d5d803b4',
  '8c764faa-f8f2-4dce-b252-429abb6d3033'];
  hideCompanyField: boolean = true;
  wsTicketKey: string;
  sfTicketKey: string;

  constructor(@Inject(LOCALE_ID) private locale: string,
    private fb: FormBuilder, public workinghoursService: WorkinghoursService, private alertifyService: AlertifyService,
     public authService: AuthService, private projectService: ProjectService, private _route: ActivatedRoute,
      private userService: UserService, private reportService: ReportService) { }

  ngOnInit() {    
    this.pagedListParams.orderCol = null;
    this.createForm();
    this._route.data.subscribe((data) => {
      this.workingHours = data.workingHours.result;
      this.pagination = data.workingHours.pagination;
      if(this.workinghoursService.currentPageNumber !== 0){
        this.pagination.currentPage = this.workinghoursService.currentPageNumber;
      }
    });
    this.getProjects();
    this.getArts();
    this.pagedListParams.userId = '00000000-0000-0000-0000-000000000000';
    this.pagedListParams.projectId = '00000000-0000-0000-0000-000000000000';
    this.pagedListParams.IsAscending = true;
    const datevm1 = new Date();
    if(this.authService.decodedToken.role === 'isAdmin'){
      this.pagedListParams.date = new Date(datevm1.getFullYear(), datevm1.getMonth(), 1);
      this.pagedListParams.endDate = new Date();
    }
    else{
      this.pagedListParams.date = '';
      this.pagedListParams.endDate = '';
    }
    this.bsConfig = {
      containerClass: 'theme-blue',
      dateInputFormat: 'DD/MM/YYYY'
    };
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.workinghoursService.currentPageNumber = this.pagination.currentPage;
    this.getWorkingHours();

  }
  
  exportToExcel() {
    const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    this.objLength = Object.keys(ws).length;
    this.removeEditColumn(ws);
    
   }
   writeToExcel(ws){
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'WorkingHours.xlsx');
   }

   removeEditColumn(obj){
    console.log(obj);
    Object.keys(obj).forEach((key) => {
      this.objLength--;
      if(obj[key].v === 'Edit'){
        delete(obj[key]);
      }
      if(this.objLength === 0){
        this.writeToExcel(obj);
      }
    })
   }

  resetFilter(){
    this.pagedListParams.userId = '00000000-0000-0000-0000-000000000000';
    this.pagedListParams.projectId = '00000000-0000-0000-0000-000000000000';
    this.pagedListParams.date = '';
    this.pagedListParams.endDate = '';
    this.pagedListParams.ticket = '';
    this.pagedListParams.gesellschaft = '';
    this.pagedListParams.artId = '';
  }

  getProjects() {
    this.projectService.getAll().subscribe((data: Project[]) => {
      this.projects = data;
    });
  }

  getArts(){
    this.workinghoursService.getArts().subscribe((data: Art[]) => {
      this.arts = data;
    });
  }

  createForm() {
    this.workinghoursform = this.fb.group({
      dateString: [new Date(), Validators.required],
      projectId: [null, Validators.required],
      userId: ['', Validators.required],
      description: [''],
      ticketWt: ['', Validators.required],
      ticketSf: [''],
      gesellschaft: [''],
      artId: [null],
      hours: ['', Validators.required]
    });
    if (this.authService.decodedToken.role === 'isAdmin') {
      this.getUsers();
    } else {
      this.workinghoursform.controls['userId'].setValue(this.authService.decodedToken.nameid);
      this.workinghoursform.controls['userId'].setValidators([Validators.required]);
      this.getProjectsByUser(this.authService.decodedToken.nameid);
    }
  }

  toggleForm() {
    this.addFormActivated = !this.addFormActivated;
    this.createForm();
    this.getWorkingHours();
  }

  exportDataToExcel() {
    debugger;
    this.workinghoursService.getDateForExcel(this.pagedListParams).subscribe((data: any) => {
      if(data.type === 4){
         const downloadedFile = new Blob([data.body], { type: "application/ms-excel" });
         var url = URL.createObjectURL(downloadedFile);
         var link = document.createElement("a");
         link.setAttribute("href", url);
         link.setAttribute("download", "WorkingHours.xlsx");
         link.style.display = "none";
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
      }
    });
  }

  getWorkingHours() {
    console.log('pagedListParams.date', this.pagedListParams);
    this.pageSizeToSend = +this.pageSizeToSend;
    this.workinghoursService.
    getWorkingHours(this.pagination.currentPage, this.pageSizeToSend, this.authService.decodedToken.nameid, this.pagedListParams)
    .subscribe((data: PaginatedResult<Workinghours[]>) => {
      this.workingHours = data.result;
      this.pagination = data.pagination;
    }, error => {
      this.alertifyService.error(error);
    });
  }

  onOptionsSelected(id){
    if(!this.webServicesProjects.includes(id)){
      this.hideCompanyField = true;
    }
    else{
      this.hideCompanyField = false;
    }

    this.workinghoursform.patchValue({
      ticketWt: this.projects.find(p => p.id === id).wsTicket,
      ticketSf: this.projects.find(x => x.id === id).sfTicket,
    });


  }

  getProjectsByUser(userId) {
    this.projectService.getByUserId(userId.toString()).subscribe((data) => {
      this.userProjects = data;
    });
  }

  onProjectSelected(){
    

  }

  saveWorkingHours() {
    if(this.workinghoursform.invalid)
    {
      this.workinghoursform.markAllAsTouched();
      return;
    }

    this.workingHoursToCreate = Object.assign({}, this.workinghoursform.value);
    this.workinghoursService.create(this.workingHoursToCreate).subscribe(() => {
      // this.toggleForm();
      // this.getWorkingHours();
      this.alertifyService.success('Working hours inserted successfully')
    }, error => {
      this.alertifyService.error(error);
    });
  }

  getUsers() {
    this.userService.getAll().subscribe((data: User[]) => {
      this.users = data;
    });
  }

  onUserChange(userValue) {
    if (this.authService.decodedToken.role === 'isAdmin') {
      this.getProjectsByUser(userValue);
    }
  }

  deleteWh(id){
    this.alertifyService.confirm('Confirm dialog','Are you sure you want to delete this', () => {
      this.workinghoursService.delete(id).subscribe(() => {
        this.alertifyService.success('Workinghour deleted successfully');
        this.getWorkingHours();
      }, error => {
        this.alertifyService.error(error);
      });
    })
  }


  setOrder(orderCol){
    debugger;
    this.pagedListParams.IsAscending = !this.pagedListParams.IsAscending;
    this.pagedListParams.orderCol = orderCol;
    // tslint:disable-next-line: max-line-length
    if(this.pagedListParams.IsAscending){
      this.pagedListParams.orderCol = this.pagedListParams.orderCol + '_asc';
    }

    this.getWorkingHours();
  }
  tdOnChange(ticket){
    console.log(this.authService.decodedToken.role)
    if(this.authService.decodedToken.role === "isAdmin"){
      this.reportService.getByTicketUsers(ticket).subscribe((data:any) => {
        this.ticketReport = [];
        this.ticketReport = data;
        this.showModal = true;
      })
    }
    else{
    this.reportService.getByTicket(ticket, this.authService.decodedToken.nameid).subscribe((data: any) =>{
      this.ticketReport = [];
      this.ticketReport = data;
      console.log(this.ticketReport)
      this.showModal = true;
    });
  }
  }

}
