import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '../_services/project.service';
import { Project } from '../models/project';
import { AlertifyService } from '../_services/alertify.service';


@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css']
})
export class CreateProjectComponent implements OnInit {
  newProjectForm: FormGroup;
  editProjectForm: FormGroup;
  project: Project;
  editedProject: Project;
  addProjectActivated: boolean;
  listProjects: boolean;

  constructor(private projectService: ProjectService, private fb: FormBuilder, private alertifyService: AlertifyService) {
    this.addProjectActivated = true; 
    this.listProjects = true; 
   }

  ngOnInit() {
    this.createNewProjectForm();
    if (this.projectService.subEditProject==undefined) {    
      this.projectService.subEditProject = this.projectService.    
      invokeEditProject.subscribe((project: Project) => {    
        this.editedProject = project;
        this.editForm();
      });    
    } 
  }


  createNewProjectForm(){
    this.newProjectForm = this.fb.group({
      name: ['', Validators.required],
      wsTicket: [''],
      sfTicket: ['']
    }, {});
    this.editProjectForm = this.fb.group({
      name: ['', Validators.required]
    }, {});
  }

  addProject(){
    if(this.newProjectForm.valid){
      this.project = Object.assign({}, this.newProjectForm.value);
      this.projectService.addProject(this.project).subscribe(() => {
        this.alertifyService.success('Project added successfully');
        this.addForm();
        this.updateList();
        this.createNewProjectForm();
      }, error => {
        this.alertifyService.error(error);
      });
    }
  }
  
  editProject(){
    let newProject: Project = Object.assign({}, this.editProjectForm.value);
    this.editedProject.name= newProject.name;
    this.projectService.editProject(this.editedProject).subscribe(() => {
      this.alertifyService.success('Project edited successfully')
      this.editForm();  
      this.createNewProjectForm();
    },  error => {
      this.alertifyService.error(error);
    });
  }

  updateList(){    
    this.projectService.onProjectCreate(); 
  }   

  editForm(){
    if(this.listProjects){
      this.listProjects=false;
      this.addProjectActivated=false;
    }
    else{
      this.listProjects=true;
      this.addProjectActivated=true;
    }
  }

  addForm(){
    if(this.listProjects){
      this.listProjects=false;
      this.addProjectActivated=true;
    }
    else{
      this.listProjects=true;
      this.addProjectActivated=false;
    }
    
  }

}
