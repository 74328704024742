import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from './_services/auth.service';
import { AuthGuardService } from './_services/authGuard.service';
import { RegisterComponent } from './register/register.component';
import { WorkinghoursComponent } from './workinghours/workinghours.component';
import { ProjectComponent } from './project/project.component';
import { ProjectService } from './_services/project.service';
import { WorkinghoursService } from './_services/workinghours.service';
import { UsersComponent } from './users/users.component';
import { UserService } from './_services/user.service';
import { UsersResolver } from './_resolvers/users.resolver';
import { WorkingHoursResolver } from './_resolvers/workinghours.resolver';
import { CreateProjectComponent } from './create-project/create-project.component';
import { ListProjectsComponent } from './list-projects/list-projects.component';
import { ErrorInterceptor } from './_services/error.interceptor';
import { HomeComponent } from './home/home.component';
import { EdituserComponent } from './edituser/edituser.component';
import { ExchangedataService } from './_services/exchangedata.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule, PaginationModule } from 'ngx-bootstrap';
import { EditworkinghoursComponent } from './editworkinghours/editworkinghours.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportService } from './_services/report.service';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { ReportforuserComponent } from './reportforuser/reportforuser.component';
import { EditprojectComponent } from './editproject/editproject.component';


@NgModule({
   declarations: [	
      AppComponent,
      NavComponent,
      LoginComponent,
      RegisterComponent,
      WorkinghoursComponent,
      ProjectComponent,
      UsersComponent,
      HomeComponent,
      CreateProjectComponent,
      ListProjectsComponent,
      EdituserComponent,
      EditworkinghoursComponent,
      ErrorpageComponent,
      ReportsComponent,
      UserprofileComponent,
      ReportforuserComponent,
      EditprojectComponent
   ],
   imports: [
      HttpClientModule,
      BrowserAnimationsModule,
      BrowserModule,
      AppRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      NgMultiSelectDropDownModule.forRoot(),
      BsDatepickerModule.forRoot(),
      PaginationModule.forRoot()
   ],
   providers: [
      AuthService,
      AuthGuardService,
      ProjectService,
      WorkinghoursService,
      UserService,
      UsersResolver,
      WorkingHoursResolver,
      ExchangedataService,
      ReportService,
      {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptor,
          multi: true,
      }
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
