import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Project } from '../models/project';
import { User } from '../models/user';
import { AlertifyService } from '../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../_services/project.service';
import { UserService } from '../_services/user.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { throwIfEmpty } from 'rxjs/operators';
import { ExchangedataService } from '../_services/exchangedata.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit {
  updateUserForm: FormGroup;
  projects: Project[];
  selectedProjects: any = [];
  dropdownSettings: any = {};
  userToUpdate: User;
  changePassword: any;
  subscription: Subscription;
  constructor(private _userService: UserService,
    private alertify: AlertifyService, private fb:
      FormBuilder, private _route: ActivatedRoute, private projectService: ProjectService,
    private router: Router, private exchangeData: ExchangedataService, private authService: AuthService) {

  }

  ngOnInit() {
    this.changePassword = this.exchangeData.changePassword;
    this.initDropDownSettings();
    this.getProjects();
    this.updateUserForm = this.fb.group({
      id: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      username: ['', Validators.required],
      projectIds: [this.selectedProjects],
      userProjects: ['']
    });


    if (this.changePassword) {
      this.updateUserForm.addControl('password',
        new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(10)]));
      this.updateUserForm.addControl('confirmPassword', new FormControl('', Validators.required));
      this.updateUserForm.setValidators(this.confirmPasswordValidator);
      this._route.paramMap.subscribe(params => {
        const id = params.get('id');
        if(this.authService.decodedToken.role === 'isAdmin'){
        this._userService.getById(id).subscribe((data: User) => {
          const user: User = data;
          const projectsFromDb = [];
          user.userProjects.forEach((item) => {
            projectsFromDb.push({ id: item.project.id, name: item.project.name });
          });
          user.projectIds = user.userProjects.map(item => item['project']);
          user.password = '';
          user.confirmPassword = '';
          this.updateUserForm.setValue(user);
        });
      } else if (this.authService.decodedToken.nameid === id){
        this._userService.getById(id).subscribe((data: User) => {
          const user: User = data;
          const projectsFromDb = [];
          user.userProjects.forEach((item) => {
            projectsFromDb.push({ id: item.project.id, name: item.project.name });
          });
          user.projectIds = user.userProjects.map(item => item['project']);
          user.password = '';
          user.confirmPassword = '';
          this.updateUserForm.setValue(user);
        });
      }
      else{
        this.router.navigate(['/home']);
        this.alertify.alert('You are unauthorized');
      }
      });
    } else {
      this._route.paramMap.subscribe(params => {
        const id = params.get('id');
        if (this.authService.decodedToken.role === 'isAdmin') {
          this._userService.getById(id).subscribe((data: User) => {
            const user: User = data;
            const projectsFromDb = [];
            user.userProjects.forEach((item) => {
              projectsFromDb.push({ id: item.project.id, name: item.project.name });
            });
            user.projectIds = user.userProjects.map(item => item['project']);
            this.updateUserForm.setValue(user);
          });
        }  else if(this.authService.decodedToken.nameid === id){
          this._userService.getById(id).subscribe((data: User) => {
            const user: User = data;
            const projectsFromDb = [];
            user.userProjects.forEach((item) => {
              projectsFromDb.push({ id: item.project.id, name: item.project.name });
            });
            user.projectIds = user.userProjects.map(item => item['project']);
            this.updateUserForm.setValue(user);
          });
        }  else {
          this.router.navigate(['/home']);
          this.alertify.alert('You are unauthorized');
        }
      });
    }
  }

  confirmPasswordValidator(g: FormGroup) {
    return g.get('password').value === g.get('confirmPassword').value ? null : { 'mismatch': true };
  }
  initDropDownSettings() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  getProjects() {
    this.projectService.getAll().subscribe((data: Project[]) => {
      this.projects = data;
      console.log(this.projects)
    });
  }

  update() {
    this.userToUpdate = Object.assign({}, this.updateUserForm.value);
    if (!this.changePassword) {
      this._userService.update(this.userToUpdate).subscribe(() => {
        this.router.navigate(['/users']);
        this.alertify.success('User updated successfully');
      }, error => {
        this.alertify.error(error);
      });
    } else {
      this._userService.updatePassword(this.userToUpdate).subscribe(() => {
        this.router.navigate(['/users']);
        this.alertify.success('Password changed successfully');
      }, error => {
        this.alertify.error(error);
      });
    }
  }

}
