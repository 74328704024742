import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Userproject } from '../models/userproject';
import { Observable, Subscription } from 'rxjs';
import { Project } from '../models/project';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    baseUrl = environment.baseUrl;
    invokeGetProjects = new EventEmitter();
    invokeEditProject = new EventEmitter();
    subGetProject: Subscription;
    subEditProject: Subscription;

    constructor(private http: HttpClient) { }

    getByUserId(userId: string): Observable<Userproject[]> {
        return this.http.get<Userproject[]>(this.baseUrl + 'project/' + userId);
    }

    addProject(project: Project) {
        return this.http.post(this.baseUrl + 'project/create', project);
    }

    listProjects(): Observable<Project[]> {
        return this.http.get<Project[]>(this.baseUrl + 'project/');
    }

    removeProject(project: Project) {
        return this.http.post(this.baseUrl + 'project/delete', project);
    }

    editProject(project: Project) {
        return this.http.post(this.baseUrl + 'project/update', project);
    }

    onProjectCreate() {
        this.invokeGetProjects.emit();
    }

    onProjectEdit(project: Project) {
        this.invokeEditProject.emit(project);
    }

    getAll(): Observable<Project[]> {
        return this.http.get<Project[]>(this.baseUrl + 'project');
    }

    getById(Id){
        return this.http.get(this.baseUrl + 'project/getById/' + Id);
    }

}
