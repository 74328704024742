import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Workinghours } from '../models/workinghours';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { PaginatedResult } from '../models/pagination';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { PaginatedWorkingHours } from '../models/paginatedWorkingHours';
import { Art } from '../models/art';

@Injectable({
  providedIn: 'root'
})
export class WorkinghoursService {

  baseUrl = environment.baseUrl;
  currentPageNumber = 0;
  lastWorkingHoursEdited = '';

  constructor(private http: HttpClient, private authService: AuthService) { }
    
  create(model: Workinghours) {
    return this.http.post(this.baseUrl + 'workinghours/create', model);
  }

  getWorkingHours(page?, itemsPerPage?, userId?, pagedListParams?): Observable<PaginatedResult<Workinghours[]>> {
    const paginatedResult: PaginatedResult<Workinghours[]> = new PaginatedResult<Workinghours[]>();

     const params: any = {}; //new HttpParams();
    //let params = new HttpParams();

    if (page !== null && itemsPerPage !== null) {
      params.pageNumber = page;
      params.pageSize = itemsPerPage;
      params.currentUser = userId;
    }
    if(pagedListParams){
      params.userId = pagedListParams.userId;
      params.projectId = pagedListParams.projectId;
      params.date = pagedListParams.date;
      params.endDate = pagedListParams.endDate;
      params.orderCol = pagedListParams.orderCol;
      params.ticket = pagedListParams.ticket;
      params.gesellschaft = pagedListParams.gesellschaft;
      params.artId = pagedListParams.artId;
    }


    // return this.http.get<Workinghours[]>(this.baseUrl + 'workinghours/getAll', {observe: 'response', params})
    // .pipe(
    //   map(response => {
    //     paginatedResult.result = response.body;
    //     if(response.headers.get('Pagination') != null){
    //       paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
    //     }
    //     return paginatedResult;
    //   })
    // )
  //}

    return this.http.post(this.baseUrl + 'workinghours/getAll', params).pipe(map((response: any) => {
    paginatedResult.result = response.workingHours;
    paginatedResult.pagination = response.pagination;
    return paginatedResult;
  })
  );
}
  getWorkingHoursById(id: string): Observable<Workinghours> {
    return this.http.get<Workinghours>(this.baseUrl + 'workinghours/getById/' + id);
  }

  getDateForExcel(model){
    // let stDate = new Date(model.date);
    // let enDate = new Date(model.endDate);
    const params: any = {};
    params.date = model.date;
    params.endDate = model.endDate;

    // return this.http.request(new HttpRequest(
    //   'GET',
    //   `${this.baseUrl + 'workinghours/exportv2/?startDate=' + stDate + '&endDate=' + enDate}`,
    //   null,
    //   {
    //     responseType: 'blob'
    //   }));

    return this.http.request(new HttpRequest(
        'POST',
        `${this.baseUrl + 'workinghours/exportv2/'}`,
        params,
        {
          responseType: 'blob'
        }));
  }

  updateWorkingHours(model: Workinghours) {
    return this.http.put(this.baseUrl + 'workinghours/update', model);
  }

  getArts(): Observable<Art[]>{
    return this.http.get<Art[]>(this.baseUrl + 'workinghours/getarts');
  }

  delete(id) {
    return this.http.delete(this.baseUrl + 'workinghours/delete/' + id);
  }

}
