import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { Project } from '../models/project';
import { ProjectService } from '../_services/project.service';
import { AlertifyService } from '../_services/alertify.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  user: User;
  selectedProjects: [];
  projects: Project[];
  dropdownSettings: any = {};
  constructor(private authService: AuthService, private alertifyService: AlertifyService, private fb: FormBuilder, private router: Router, private projectService: ProjectService) { }

  ngOnInit() {
   this.createRegisterForm();
   this.getProjects();
   this.initDropDownSettings();
  }

  createRegisterForm(){
    this.registerForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      username: ['', Validators.required],
      password: ['',  [Validators.required, Validators.minLength(5), Validators.maxLength(10)]],
      confirmPassword: ['', Validators.required],
      projectIds: [this.selectedProjects]
    }, { validator: this.confirmPasswordValidator });
  }

  getProjects() {
    this.projectService.getAll().subscribe((data: Project[]) => {
      this.projects = data;
      console.log(data);
    });
  }

  initDropDownSettings(){
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
  }

  confirmPasswordValidator(g: FormGroup) {
    return g.get('password').value === g.get('confirmPassword').value ? null : {'mismatch': true };
  }

  register() {
    console.log(this.selectedProjects);
    if (this.registerForm.valid) {
      this.user = Object.assign({}, this.registerForm.value);
      this.authService.register(this.user).subscribe(() => {
        this.alertifyService.success('user registered');
      }, error => {
        console.log(error)
        this.alertifyService.error(error);
        console.log(error)
      }, () => {
        this.authService.login(this.user).subscribe(() => {
          this.router.navigate(['/workinghours']);
        });
      });
    }
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

}
