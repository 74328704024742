import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from '../models/project';
import { AlertifyService } from '../_services/alertify.service';
import { ProjectService } from '../_services/project.service';

@Component({
  selector: 'app-editproject',
  templateUrl: './editproject.component.html',
  styleUrls: ['./editproject.component.css']
})
export class EditprojectComponent implements OnInit {
  editProjectForm: FormGroup

  constructor(private route: ActivatedRoute, private router: Router, private alertifyService: AlertifyService, private fb: FormBuilder, private projectService: ProjectService) { }

  ngOnInit() {
    this.editProjectForm = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      wsTicket: [''],
      sfTicket: ['']
    });

    this.route.paramMap.subscribe(params => {
      debugger;
      const id = params.get('id');
      this.projectService.getById(id).subscribe((data: Project) => {
       console.log("project", data);
        this.editProjectForm.patchValue({
          id: data.id,
          name: data.name,
          wsTicket: data.wsTicket,
          sfTicket: data.sfTicket
        })
      });
    });


  }

  saveProject() {
      const modelToUpdate = Object.assign({}, this.editProjectForm.value);
      this.projectService.editProject(modelToUpdate).subscribe(() =>{
        this.router.navigate(['/createproject']);
        this.alertifyService.success("Project updated successfully")
      });
    }

}
