import { Injectable } from '@angular/core';
import { UserService } from '../_services/user.service';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { User } from '../models/user';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UsersResolver implements Resolve<User>{
    constructor(private _userService: UserService, private router: Router){}

    resolve(route: ActivatedRouteSnapshot): Observable<User> {
        return this._userService.getAll().pipe(
            catchError(error => {
                console.log(error);
                this.router.navigate(['/error']);
                return of(null);
            })
        );
    }
}