import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  // tslint:disable-next-line: variable-name
  constructor(private router: Router,  private _authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //if (this._authService.isUserLoggedIn()) { return true; }
    if (this._authService.isUserLoggedIn()) {
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(this._authService.decodedToken.role) === -1) {
          // role not authorised so redirect to home page
          this.router.navigate(['/']);
          return false;
      }

      // authorised so return true
      return true;
  }

    this.router.navigate(['']);
    return false;

  }

  canActivateAdmin(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this._authService.decodedToken.role === 'isAdmin') {
      return true;
    }

    this.router.navigate(['/home']);
    return false;
  }

}
