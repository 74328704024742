import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReportService } from '../_services/report.service'
import { ProjectService } from '../_services/project.service';
import { Project } from '../models/project';
import { UserService } from '../_services/user.service';
import { User } from '../models/user';
import { AuthService } from '../_services/auth.service';
import * as xlsx from 'xlsx'

@Component({
  selector: 'app-reportforuser',
  templateUrl: './reportforuser.component.html',
  styleUrls: ['./reportforuser.component.css']
})
export class ReportforuserComponent implements OnInit {
  reportform: FormGroup;
  pagedListParams: any = {};
  report: any = [];
  projects: Project[];
  users: User[];
  showNoData: boolean = false;
  disableDate: boolean = false;
  disableFields: boolean = false;
  hrId: string = 'd92cea88-b4d0-49ab-8488-36be189e991e';
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  constructor(private fb: FormBuilder, private reportService: ReportService,
              private projectService: ProjectService, private userService: UserService, public authService: AuthService) { }

  ngOnInit() {
    this.getUsers();
    this.getProjects();
    const datevm = new Date();
    this.pagedListParams.startDateString = new Date(datevm.getFullYear(), datevm.getMonth(), 1);
    this.pagedListParams.endDateString = new Date();
    this.pagedListParams.selectVal = 'rep_by_days';
  }

  selectChange() {
    console.log(this.pagedListParams.selectVal);
    if(this.pagedListParams.selectVal === 'rep_by_days'){
      this.pagedListParams.notimeperiod = false;
      this.disableFields = true;
      this.disableDate = false;
    }
    else{
      this.disableFields = false;
    }
    this.report = {};
  }

  toggleDate(){
    this.disableDate = !this.disableDate;
  }

  getReport(){
    this.pagedListParams.userId = this.authService.decodedToken.nameid;
    if(this.pagedListParams.selectVal === 'rep_by_ticket'){
      this.reportService.getByTicketReport(this.pagedListParams).subscribe((data:any) => {
        this.report = [];
        if(data.length === 0){
          this.showNoData = true;
        }
        else{
          this.showNoData = false;
        }
        this.getSumHours(data);
      });
    }
    else{
      if(this.pagedListParams.selectVal === 'rep_by_days' && this.authService.decodedToken.nameid === this.hrId){
        this.pagedListParams.userId = this.pagedListParams.selectedUser;
      }
      this.reportService.getGrouppedByDate(this.pagedListParams).subscribe((data: any) => {
        this.report = [];
        if(data.length === 0){
          this.showNoData = true;
        }
        else{
          this.showNoData = false;
        }
        this.getSumHours(data);
      });
    }
  }

  getSumHours(data){
    let sum = 0;
    let arrlength = data.length;
    for(const item of data){
      arrlength--;
      sum += item.hours;
      if(arrlength === 0) {
        this.report = data;
        this.report.sum = sum;
      }
    }
  }

  exportToExcel() {
    const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    this.writeToExcel(ws);
   }

   writeToExcel(ws){
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'WorkingHours.xlsx');
   }

  getProjects() {
    this.projectService.getAll().subscribe((data: Project[]) => {
      this.projects = data;
    });
  }

  getUsers() {
    this.userService.getAll().subscribe((data: User[]) => {
      this.users = data;
    });
  }

}
