import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { WorkinghoursComponent } from './workinghours/workinghours.component';
import { AuthGuardService } from './_services/authGuard.service';
import { UsersComponent } from './users/users.component';
import { UsersResolver } from './_resolvers/users.resolver';
import { WorkingHoursResolver } from './_resolvers/workinghours.resolver';
import { CreateProjectComponent } from './create-project/create-project.component';
import { HomeComponent } from './home/home.component';
import { EdituserComponent } from './edituser/edituser.component';
import { EditworkinghoursComponent } from './editworkinghours/editworkinghours.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportforuserComponent } from './reportforuser/reportforuser.component';
import { EditprojectComponent } from './editproject/editproject.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'userreport', component: ReportforuserComponent},
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuardService], data: { roles: ['isAdmin'] } },
  { path: 'error', component: ErrorpageComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: 'workinghours', component: WorkinghoursComponent, resolve: { workingHours: WorkingHoursResolver },canActivate: [AuthGuardService] },
  { path: 'users', component: UsersComponent, resolve: { users: UsersResolver }, 
    canActivate: [AuthGuardService], data: { roles: ['isAdmin'] }},
  { path: 'createproject', component: CreateProjectComponent, canActivate: [AuthGuardService], data: { roles: ['isAdmin'] }},
  { path: 'edituser/:id', component: EdituserComponent, canActivate: [AuthGuardService] },
  { path: 'editworkinhours/:id', component: EditworkinghoursComponent, canActivate: [AuthGuardService] },
  { path: 'editproject/:id', component: EditprojectComponent, canActivate: [AuthGuardService], data: { roles: ['isAdmin']}},
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
