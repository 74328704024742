import { Component, OnInit } from '@angular/core';
import { User } from '../models/user';
import { UserService } from '../_services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { ExchangedataService } from '../_services/exchangedata.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  users: User[];
  constructor(private _userSerivce: UserService, private alertify: AlertifyService, private _route: ActivatedRoute, private exchanData: ExchangedataService) { }

  ngOnInit() {
    this._route.data.subscribe((data) => {
      this.users = data.users;
      console.log(data.users)
    });
   
  }

  getAllUser() {
    this._userSerivce.getAll().subscribe((data: User[]) => {
      this.users = data;
    }, error => {
      this.alertify.error(error);
    });
  }

  getByProject(projectId) {
    this._userSerivce.getByProjectId(projectId).subscribe((data: User[]) => {
      this.users = data;
    });
  }

  changePassword(user: User, event: string) {
    if (event === 'changepassword') {
      this.exchanData.changePassword = true;
    } else {
      this.exchanData.changePassword = false;
    }
  }

}
